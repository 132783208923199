@value md, xl from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value breakpointLgUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";

@layer components {
  .container {
    overflow: auto;
    padding: 0;
  }

  .items {
    padding: md;
    overflow: auto;

    @media breakpointLgUp {
      max-height: 60vh;
    }
  }

  .wrapper--noResults {
    text-align: center;
    margin: 80px 0;

    @media breakpointLgUp {
      margin: 120px 0;
    }
  }
}
